<template>
    <div class="infos">
        <div class="info">
            <i class="fas fa-bookmark start"></i>
            <div class="text title" @click="editAction">
                {{ name !== "" && name !== null ? name : "--" }}
            </div>
            <i
                v-if="checkRight('G_RESIDENCES')"
                class="fas fa-pencil-alt modify-icon end"
            ></i>
        </div>
        <div class="info">
            <i class="fas fa-map-marker-alt start"></i>
            <div class="text title" @click="editAction">
                {{ adress !== "" && adress !== null ? adress : "--" }}
            </div>
            <i
                v-if="checkRight('G_RESIDENCES')"
                class="fas fa-pencil-alt modify-icon end"
            ></i>
        </div>
        <div class="info">
            <i class="fas fa-comment start"></i>
            <div class="text comment" @click="editAction">
                {{ comment }}
            </div>
            <i
                v-if="checkRight('G_RESIDENCES')"
                class="fas fa-pencil-alt modify-icon end"
            ></i>
        </div>
    </div>
    <section class="image mobile">
        <div class="picto">
            <div class="big-picture">
                <img
                    alt="building"
                    :src="
                        require('@/assets/images/buildings/building-medium.png')
                    "
                    width="100"
                />
            </div>
        </div>
    </section>
</template>

<script>
export default {
    name: "Infos",
    props: ["name", "adress", "comment"],
    methods: {
        editAction() {
            this.$emit("editAction")
        },
    },
}
</script>

<style lang="scss" scoped>
@import "@/assets/scss/_loader.scss";
@import "@/assets/scss/details.scss";

@media all and (max-width: 1024px) {
    .mobile {
        display: none;
    }
}
@media all and (min-width: 1024px) {
    .no-mobile {
        display: none;
    }
}
</style>
