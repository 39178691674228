<template>
    <div class="main-panel">
        <!-- RETURN BUTTON -->
        <div class="return_button">
            <router-link class="" :to="'/' + entityParent + '/' + idParent">
                <i class="fas fa-arrow-left"></i>
                {{ $t("navigation.residenceBack") }}
            </router-link>
        </div>

        <!-- INFORMATIONS -->
        <div
            class="informations-bloc blueType mobile"
            @click="checkRight('G_RESIDENCES')"
        >
            <Infos
                :name="name"
                :adress="adress"
                :comment="comment"
                @editAction="editAction(1)"
            />
        </div>

        <hr class="mobile" />

        <!-- TABS -->
        <div class="tabs-bloc">
            <ul class="list-group">
                <!-- Details -->
                <li
                    class="list-group-item"
                    :class="{ activeTab: activeTab == 'details' }"
                    @click="changeTab('details')"
                >
                    <i class="fas fa-city"></i>
                    <span class="mobile">
                        {{ $t("entityDetails.tabs.buildingDetails") }}
                    </span>
                </li>
                <!-- Authorizations -->
                <li
                    v-if="checkRight('G_AUTOS')"
                    class="list-group-item"
                    :class="{ activeTab: activeTab == 'authorizations' }"
                    @click="changeTab('authorizations')"
                >
                    <i class="far fa-lightbulb"></i>
                    <span class="mobile">
                        {{ $t("entityDetails.tabs.authorisations") }}
                    </span>
                </li>
                <!-- Codes -->
                <li
                    v-if="checkRight('G_CODE_CLAV') || checkRight('G_CODE_TEL')"
                    class="list-group-item"
                    :class="{ activeTab: activeTab == 'codes' }"
                    @click="changeTab('codes')"
                >
                    <i class="far fa-keyboard"></i>
                    <span class="mobile">
                        {{ $t("entityDetails.tabs.codes") }}
                    </span>
                </li>
                <!-- Central units -->
                <li
                    class="list-group-item"
                    :class="{ activeTab: activeTab == 'central-units' }"
                    @click="changeTab('central-units')"
                >
                    <i class="fas fa-server"></i>
                    <span class="mobile">
                        {{ $t("entityDetails.tabs.centralUnits") }}
                    </span>
                </li>
                <!-- Gestion Locative -->
                <li
                    class="list-group-item"
                    :class="{ activeTab: activeTab == 'manage' }"
                    @click="
                        manage ? goToV1View('data/location') : editAction(0)
                    "
                    v-if="!isBasic"
                >
                    <i class="fas fa-users"></i>
                    <span class="mobile">
                        {{ $t("entityDetails.tabs.manage") }}
                    </span>
                    <i
                        v-if="manage"
                        class="fas fa-check-circle manage-check mobile"
                    ></i>
                    <i
                        v-else
                        class="fas fa-times-circle manage-uncheck mobile"
                    ></i>
                </li>
                <!-- Subaccounts -->
                <li
                    class="list-group-item"
                    :class="{ activeTab: activeTab == 'subaccounts' }"
                    @click="changeTab('subaccounts')"
                >
                    <i class="fas fa-user-friends"></i>
                    <span class="mobile">
                        {{ $t("entityDetails.tabs.subaccounts") }}
                    </span>
                </li>
            </ul>
        </div>
    </div>
</template>

<script>
import Infos from "./Infos.vue"
import v1mixin from "@/mixins/v1.js"
export default {
    name: "LeftMenu",
    mixins: [v1mixin],

    data() {
        return {
            manage: this.$store.state.account.manage,
        }
    },

    props: {
        entityParent: {
            type: String,
            default: "",
        },
        entityType: {
            type: Object,
            default: () => {
                return {}
            },
        },
        displayed: {
            type: Boolean,
            default: false,
        },
        activeTab: {
            type: String,
            default: "",
        },
        id: {
            type: String,
            default: "",
        },
        name: {
            type: String,
            default: "",
        },
        adress: {
            type: String,
            default: "",
        },
        comment: {
            type: String,
            default: "",
        },
        idParent: {
            type: String,
            default: "",
        },
    },

    computed: {
        isBasic() {
            return this.$store.state.account.type === 2
        },
        isSimplified() {
            return this.$store.state.account.type === 1
        },
        isComplex() {
            return this.$store.state.account.type === 0
        },
    },

    methods: {
        changeTab(tabLabel) {
            this.$emit("updateActiveTab", tabLabel)
            this.$router.push({
                params: {
                    id: this.$route.params.id,
                    tab: tabLabel,
                },
            })
        },

        editAction(value) {
            this.$emit("editAction", value)
        },
    },

    components: {
        Infos,
    },
}
</script>

<style lang="scss" scoped>
@import "@/assets/scss/global.scss";
@import "@/assets/scss/details.scss";
@import "@/assets/scss/variables/fontSize.scss";

@media all and (min-width: 1024px) {
    .no-mobile {
        display: none;
    }
}

@media all and (max-width: 1024px) {
    .mobile {
        display: none;
    }
}
</style>
